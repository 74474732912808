<template>
  <v-container class="h-100 terms">
    <v-row class="h-100 align-center" justify="center">
      <v-card elevation="0" class="justify-center col col-9">
        <v-card-title class="justify-center">
          <img
            alt="logo"
            :src="
              $store.getters['auth/user']
                ? $store.getters['auth/user'].theme_config.header_logo
                : ''
            "
            class="logo"
          />
        </v-card-title>

        <!-- class="h-100 d-flex align-center justify-center flex-column" -->
        <div class="pt-5" v-if="err">
          <div class="v-input__control d-flex justify-center alert-container">
            <v-alert text outlined class="red-alert">
              {{ $t("something_went_wrong") }}
            </v-alert>
          </div>
        </div>

        <v-card
          :class="`terms-link mb-5 ${err ? 'mt-2' : 'mt-0'}`"
          elevation="0"
        >
          <h1 class="main-title">Your data and privacy</h1>
          <p class="headline mb-1">
            In compliance with data protection legislation, we inform you of the
            following regarding your personal data:
          </p>
          <h3 class="headline">Responsible</h3>
          <p>SALUMEDIA LABS, S.L.U, (B 91968685).</p>
          <h3 class="headline">Purpose</h3>
          <p>
            The requested data are necessary to provide you with the service of
            providing recommendations of trained health professionals through
            the application. Likewise, the data processing may be used for
            profiling purposes in case of your explicit consent.
          </p>
          <h3 class="headline">Legitimation</h3>
          <p>Your consent and performance of contractual performance.</p>
          <h3 class="headline">Recipients</h3>
          <p>
            Your data will only be communicated to the suppliers with whom we
            work and the health professionals with whom we collaborate to offer
            you the service or in case of legal and/or judicial obligation. No
            data will be transferred to countries outside the European Union.
          </p>
          <h3 class="headline">Preservation</h3>
          <p>
            We will keep your data for as long as your account is active as a
            customer of Adhera, unless there is a specific legal obligation to
            keep it.
          </p>
          <h3 class="headline">Exercise of rights</h3>
          <p>
            You have the right to revoke your consent, as well as to access,
            rectify, delete, limit, oppose, or request the portability of your
            personal data by sending an email to privacy@salumedia.com or by
            sending a letter to Avenida República Argentina number 24, Edificio
            Torre de los Remedios, 5th floor, module A, 41011 Seville (Spain).
            You can also complain to the AEPD through:
            <a href="https://www.aepd.es" class="bold">www.aepd.es</a>
          </p>
        </v-card>

        <v-divider></v-divider>

        <v-flex class="justify-center">
          <v-checkbox
            name="accept_terms"
            id="accept_terms"
            :true-value="true"
            :false-value="false"
            v-model="terms"
          >
            <template v-slot:append>
              
                I have read the
                <a href="#" class="bold">Terms of Use</a> and the
                <a href="#" class="bold"
                  >Privacy and Cookie Policy</a
                >
                and agree that my personal data may be processed for the purpose
                of providing telemedicine as well as for scientific research or
                statistical purposes.

            </template>
          </v-checkbox>

          <div
            class="v-input__control d-flex flex-row proceed_btn mt-8"
          >
            <v-btn :disabled="!valid" @click="submit">
              {{ $t("continue") }}
            </v-btn>
          </div>
        </v-flex>
      </v-card>
    </v-row>
  </v-container>
</template>

<script>
import axios from "axios";

export default {
  data() {
    return {
      err: null,
      terms: false,
      valid: false,
    };
  },

  watch: {
    terms: function (val) {
      return (this.valid = val == true ? true : false);
    },
  },

  methods: {
    async submit() {
      let res = await axios.post(
        "/dashboard/users/update-terms-conditions-attribute",
        {
          is_terms_conditions: this.terms,
        }
      );

      if (res.status == 200) {
        // refetching user
        await this.$store.dispatch(
          "auth/attempt",
          this.$store.getters["auth/authenticated"].mc_access_token
        );

        if (this.$store.getters["auth/user"].is_terms_conditions) {
          return this.$router.push({ name: "members-list" });
        }
      }

      this.err = true;
    },
  },
};
</script>

<style lang="scss" src="@/assets/sass/views/terms.scss">
</style>
